import { Spacer, Text, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import Link from 'next/link';
import React from 'react';
import { Search as SearchIcon } from 'react-feather';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { useIsSSR } from '../../../../hooks/useIsSSR';
import { openCommandMenu } from '../../../command-menu/useCommandMenu';
import { IUser } from '../../../state/interfaces/user';
import { User } from '../user';
import { Desktop, Mobile } from '../viewport';
import { INavItem, NavItem } from './item';
import { IPagination, Pagination } from './pagination';

let Header = styled('header')`
  z-index: 100;
  position: sticky;
  top: 0px;
  background: var(--vapor-background);
`;

let NavLayer = styled('nav')`
  border-bottom: var(--vapor-border);
  overflow: auto;
`;

let ItemSpacer = styled('div')`
  margin: 15px 0px;
  display: flex;
  height: 30px;
`;

let NavInner = styled('div')`
  padding: 0px 20px;
  display: flex;
  max-width: 1200px;
  margin: 0px auto;

  &:not(.mobile) {
    @media screen and (max-width: 800px) {
      grid-template-columns: auto min-content;
    }
  }
`;

let NavLeft = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-grow: 1;
`;

let NavRight = styled('div')`
  display: flex;
`;

let SearchWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

let Search = styled('button')`
  height: 34px;
  padding: 0px 10px;
  display: flex;
  border-radius: 7px;
  border: none;
  background: var(--vapor-accent-2);
  color: var(--vapor-accent-7);
  display: flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 6px;
  }

  p {
    height: 16px;
    line-height: 16px;
    font-size: 16px;
    margin-right: 100px;
  }
`;

export interface IBreadcrumb {
  name: string;
  action?: string | (() => unknown);
}

export let Nav = ({
  items,
  logo,
  subnavItems,
  name,
  pagination,
  user
}: {
  items?: INavItem[];
  subnavItems?: INavItem[];
  logo: React.ReactNode;
  name: React.ReactNode;
  pagination?: IPagination[];
  user: IUser;
}) => {
  let isSSR = useIsSSR();
  let isMobile = useIsMobile();

  return (
    <Header>
      <NavLayer>
        <NavInner>
          <NavLeft>
            <Link href="/">
              <a>
                <ItemSpacer>
                  <VerticalCenter>
                    <div style={{ display: 'flex' }}>{logo}</div>
                  </VerticalCenter>

                  <Desktop>
                    <Spacer width={15} />

                    <VerticalCenter>
                      <Text as="h1" size={16} weight={600}>
                        {name}
                      </Text>
                    </VerticalCenter>
                  </Desktop>
                </ItemSpacer>
              </a>
            </Link>

            {pagination && !isSSR && (
              <ItemSpacer>
                <Pagination isMobile={isMobile} pagination={pagination} />
              </ItemSpacer>
            )}

            <Desktop>
              <SearchWrapper>
                <Search onClick={() => openCommandMenu()}>
                  <figure>
                    <SearchIcon />
                  </figure>
                  <p>Search</p>
                </Search>
              </SearchWrapper>
            </Desktop>
          </NavLeft>

          <NavRight>
            <Desktop>
              {(items || []).map((item, i) => (
                <NavItem {...item} key={i} />
              ))}
            </Desktop>

            <Spacer width={15} />

            <ItemSpacer>
              <User user={user} />
            </ItemSpacer>
          </NavRight>
        </NavInner>
      </NavLayer>

      {items?.length > 0 && !(subnavItems && subnavItems.length > 0) && (
        <Mobile>
          <NavLayer>
            <NavInner>
              <NavLeft>
                {(items || []).map((item, i) => (
                  <NavItem {...item} key={i} sub />
                ))}
              </NavLeft>
            </NavInner>
          </NavLayer>
        </Mobile>
      )}

      {subnavItems && subnavItems.length > 0 && (
        <NavLayer>
          <NavInner>
            <NavLeft>
              {(subnavItems || []).map((item, i) => (
                <NavItem {...item} key={i} sub />
              ))}
            </NavLeft>
          </NavInner>
        </NavLayer>
      )}
    </Header>
  );
};
