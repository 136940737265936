import { Avatar, Popover, Text } from '@vapor/ui';
import { styled } from 'buttered';
import Link from 'next/link';
import { config } from '../../../config';
import { IUser } from '../../state/interfaces/user';

let Wrapper = styled('div')`
  height: 30px;
  width: 30px;
  text-align: left;
`;

let AvatarWrapper = styled('div')`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: var(--vapor-accent-3);

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
`;

let Section = styled('section')`
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 100%;

  a {
    height: 30px;
    padding: 0px 10px;
    transition: all 0.3s;
    display: block;
    line-height: 30px;
    border-radius: 7px;
    font-size: 14px;
    color: var(--vapor-accent-8);

    &:hover {
      color: var(--vapor-foreground);
      background: var(--vapor-accent-3);
    }
  }
`;

let Header = styled('header')`
  display: grid;
  gap: 10px;
  grid-template-columns: 30px 1fr;
  padding: 5px;
`;

let HR = styled('hr')`
  border: none;
  background: var(--vapor-accent-4);
  height: 1px;
  padding: 0px;
  margin: 0px;
`;

export let User = ({ user }: { user: IUser }) => {
  if (!user) {
    return (
      <Wrapper>
        <AvatarWrapper>
          {user?.photoUrl && <img src={user.photoUrl} alt={user.name} />}
        </AvatarWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Popover
        popover={() => (
          <>
            <Section>
              <Header>
                <Avatar src={user.photoUrl} name={user.name} size={30} />

                <div>
                  <Text weight={600} size={18}>
                    {user.name}
                  </Text>
                  <Text size={12} style={{ opacity: 0.6 }}>
                    {user.email}
                  </Text>
                </div>
              </Header>
            </Section>

            <HR />

            <Section>
              <Link href="/account">
                <a>Your Account</a>
              </Link>

              <Link href="/">
                <a>Your Calendars</a>
              </Link>
            </Section>

            <HR />

            <Section>
              <a href={`${config.auth}/logout`}>Logout</a>
            </Section>
          </>
        )}
        mobile={{
          type: 'drawer'
        }}
        styles={{
          popoverInner: { padding: 0, borderRadius: 15, overflow: 'hidden' }
        }}
      >
        {({ ref, attrs }) => (
          <AvatarWrapper ref={ref} {...attrs}>
            {user?.photoUrl && <img src={user.photoUrl} alt={user.name} />}
          </AvatarWrapper>
        )}
      </Popover>
    </Wrapper>
  );
};
