import { useShortcut } from 'litkey';
import { Fragment } from 'react';
import {
  closeCommandMenu,
  IAction,
  openCommandMenu,
  useCommandMenuOpen,
  useCurrentActionState,
  useKeyboardShortcutActions
} from './useCommandMenu';

let Shortcut = ({ action }: { action: IAction }) => {
  let [currentAction] = useCurrentActionState();
  let [isOpen] = useCommandMenuOpen();

  useShortcut(
    action.shortcut,
    e => {
      let textShortcut = (
        Array.isArray(action.shortcut) ? action.shortcut : [action.shortcut]
      ).some(s => s.length == 1 || s.includes('Arrow') || s.includes('+A'));

      if (textShortcut) {
        if ((e.target as HTMLInputElement).tagName === 'INPUT') return;
        if ((e.target as HTMLInputElement).tagName === 'TEXTAREA') return;
      }

      e.preventDefault();

      if (action.perform) {
        action.perform();
      } else {
        if (currentAction === action.id && isOpen) {
          closeCommandMenu();
        } else {
          openCommandMenu({
            currentAction: action.id
          });
        }
      }
    },
    [action, currentAction, isOpen]
  );

  return null;
};

export let Shortcuts = () => {
  let shortcuts = useKeyboardShortcutActions();

  return (
    <Fragment>
      {shortcuts.map(action => (
        <Shortcut key={action.id} action={action} />
      ))}
    </Fragment>
  );
};
