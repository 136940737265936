import { useRouter } from 'next/router';
import { useMemo } from 'react';
import {
  Calendar,
  Check,
  CreditCard,
  Grid,
  Home,
  Moon,
  Settings,
  Shield,
  Smartphone,
  Sun,
  Umbrella,
  User,
  Users
} from 'react-feather';
import { IAction, useActions } from '../command-menu/useCommandMenu';
import { setTheme } from './atoms/theme';
import { client, getAuthHeaders } from './sdk/client';
import { useBoot } from './useBoot';

export let useGlobalActions = () => {
  let router = useRouter();

  let setThemeFlag = async (theme: string) => {
    client
      .put(
        '/user/flags',
        {
          theme
        },
        {
          headers: await getAuthHeaders()
        }
      )
      .catch(() => {});
  };

  useActions([
    {
      id: 'home',
      name: 'Home',
      shortcut: ['h'],
      icon: <Home />,
      keywords: 'teams calendars boards lists',
      perform: () => router.push('/')
    },
    {
      id: 'today',
      name: 'Today',
      shortcut: ['t'],
      icon: <Check />,
      keywords: 'now tasks',
      perform: () => router.push('/today')
    },
    {
      id: 'account',
      name: 'Account',
      icon: <User />,
      keywords: 'user profile person me'
    },
    {
      id: 'account/profile',
      name: 'Profile',
      keywords: 'user profile person me',
      parent: 'account',
      icon: <User />,
      perform: () => router.push('/account')
    },
    {
      id: 'account/teams',
      name: 'Teams',
      keywords: 'team member memberships',
      parent: 'account',
      icon: <Users />,
      perform: () => router.push('/account/teams')
    },
    {
      id: 'account/gcal',
      name: 'Google Calendar',
      keywords: 'google calendar sync import external integration',
      parent: 'account',
      icon: <Users />,
      perform: () => router.push('/account/gcal')
    },
    {
      id: 'account/login',
      name: 'Login Methods',
      keywords: 'auth authentication login google email',
      parent: 'account',
      icon: <Shield />,
      perform: () => router.push('/account/login')
    },
    {
      id: 'account/devices',
      name: 'Devices',
      keywords: 'session computer phone laptop device login',
      parent: 'account',
      icon: <Smartphone />,
      perform: () => router.push('/account/devices')
    },
    {
      id: 'theme',
      name: 'Theme',
      keywords: 'theme colors colorscheme',
      icon: <Moon />
    },
    {
      id: 'theme/light',
      name: 'Light',
      keywords: 'light white',
      icon: <Sun />,
      parent: 'theme',
      perform: () => {
        setTheme('light');
        setThemeFlag('light');
      }
    },
    {
      id: 'theme/dark',
      name: 'Dark',
      keywords: 'dark black',
      icon: <Moon />,
      parent: 'theme',
      perform: () => {
        setTheme('dark');
        setThemeFlag('dark');
      }
    },
    {
      id: 'theme/system',
      name: 'System',
      keywords: 'default os',
      icon: <Umbrella />,
      parent: 'theme',
      perform: () => {
        setTheme('system');
        setThemeFlag('system');
      }
    }
  ]);
};

export let useAsyncActions = () => {
  let { teams } = useBoot();
  let router = useRouter();

  let actions = useMemo(() => {
    let actions: IAction[] = [];

    if (teams) {
      for (let team of teams) {
        let teamPrefix = `team/${team.id}`;

        actions = [
          ...actions,
          {
            id: teamPrefix,
            name: team.name,
            keywords: 'team',
            searchPlaceholder: 'Search team settings, calendars and boards',
            selectedName: team.name,
            icon: (
              <img
                src={team.photoUrl}
                alt="Avatar"
                style={{ width: 20, height: 20, borderRadius: 50 }}
              />
            )
          },
          {
            id: teamPrefix + '/settings',
            name: 'Settings',
            keywords: 'team settings profile',
            perform: () => router.push(`/team/${team.id}`),
            parent: teamPrefix,
            icon: <Settings />
          },
          {
            id: teamPrefix + '/subscription',
            name: 'Subscription',
            keywords: 'team settings subscription payment billing',
            perform: () => router.push(`/team/${team.id}/subscription`),
            parent: teamPrefix,
            icon: <CreditCard />
          },
          {
            id: teamPrefix + '/members',
            name: 'Members',
            keywords: 'team settings members users people',
            perform: () => router.push(`/team/${team.id}/members`),
            parent: teamPrefix,
            icon: <Users />
          }
        ];

        for (let calendar of team.calendars) {
          actions = [
            ...actions,
            {
              id: teamPrefix + '/' + calendar.id,
              name: calendar.name,
              keywords: 'calendar board task list',
              perform: () => {
                window.location.href = `/view/${calendar.id}`;
              },
              parent: teamPrefix,
              icon: calendar.isBoard ? <Grid /> : <Calendar />
            }
          ];
        }
      }
    }

    return actions;
  }, [teams]);

  useActions(actions, [actions]);
};
