import { keyframes, styled } from 'buttered';
import clsx from 'clsx';
import { useShortcut } from 'litkey';
import React, { useEffect, useRef } from 'react';
import { Search } from 'react-feather';
import { useVirtual } from 'react-virtual';
import useDelayed from 'use-delayed';
import { useNavigateAnimation } from './animation';
import { formatKeyboardShortcut } from './lib';
import { Result } from './result';
import { IAction, useCommandMenu, useCommandMenuOpen } from './useCommandMenu';

export interface ITips {
  id: string;
  name: string;
}

let fadeInModal = keyframes`
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
`;

let fadeInBackground = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

let fadeOutModal = keyframes`
  to {
    transform: scale(0.9);
  }

  from {
    transform: scale(1);
  }
`;

let fadeOutBackground = keyframes`
  to {
    opacity: 0;
  }

  from {
    opacity: 1;
  }
`;

let Wrapper = styled('div')`
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  background: var(--bar-background, rgba(200, 200, 200, 0.3));
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;

  &.isOpen {
    animation: ${fadeInBackground} 0.05s ease-in-out forwards;
  }

  &.isClosed {
    animation: ${fadeOutBackground} 0.15s ease-in-out forwards;
  }
`;

let Modal = styled('div')`
  width: 600px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: var(--bar-shadow, 0px 0px 20px rgba(0, 0, 0, 0.2));
  background: white;

  &.isOpen {
    animation: ${fadeInModal} 0.2s cubic-bezier(0.19, 0.92, 0.43, 1.6);
  }

  &.isClosed {
    animation: ${fadeOutModal} 0.3s cubic-bezier(0.19, 0.92, 0.43, 1.6) forwards;
  }
`;

let ModalInner = styled('div')``;

let Header = styled('header')`
  padding: 13px 24px;
  border-bottom: 1px solid #333;
  background: black;

  .inner {
    display: flex;
  }
`;

export let KBD = styled('kbd')<{ highlight?: boolean }>`
  display: flex;
  align-items: center;
  font-family: unset;
  user-select: none;
  height: 20px;

  span {
    border: 1px solid #313131;
    border-radius: 5px;
    color: #ccc;

    font-size: 8px;
    padding: 0px 6px;
    height: 20px;
    line-height: 17px;
    display: flex;
  }

  ${p =>
    p.highlight
      ? `
    span {
      border: 1px solid #555;
      color: white;
    }
  `
      : ''}
`;

let LogoWrapper = styled('div')`
  width: 30px;
  height: 30px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

let Input = styled('div')`
  flex-grow: 1;

  input {
    line-height: 30px;
    font-size: 14px;
    height: 30px;
    width: 100%;
    padding: 0px;
    background: none;
    border: none;
    outline: none;
    color: white;
  }
`;

let CurrentAction = styled('div')`
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0px 10px;
  background: #333;
  border-radius: 7px;
  color: #ddd;
  margin-right: 11px;
`;

let Tips = styled('div')`
  display: flex;
  gap: 8px;
  margin-top: 12px;

  button {
    height: 24px;
    line-height: 22px;
    padding: 0px 6px;
    font-size: 12px;
    background: none;
    border-radius: 5px;
    color: #ccc;
    border: 1px solid #333;
  }
`;

let Results = styled('main')`
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: #222;

  max-height: 350px;
  position: relative;
  overflow: auto;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

let CommandMenuInner = ({
  placeholder,
  tips,
  logo
}: {
  placeholder?: string;
  tips?: ITips[];
  logo?: React.ReactElement;
}) => {
  let {
    history,
    setCurrentAction,
    currentAction,
    setIsOpen,
    isOpen,
    setSearch,
    search,
    results,
    focusNextItem,
    focusPreviousItem,
    focussedItem,
    setFocussedItem,
    currentActionFull,
    focussedItemIndex
  } = useCommandMenu();
  let animation = useNavigateAnimation(currentAction, isOpen);

  let inputRef = useRef<HTMLInputElement>();
  let parentRef = useRef<HTMLDivElement>();
  let lastFocussedItemIndex = useRef(focussedItemIndex);
  let resultsLength = useRef(results.length);

  useEffect(() => {
    if (focussedItemIndex == 0 && lastFocussedItemIndex.current != 0) {
      parentRef.current.scrollTop = 0;
    } else if (
      lastFocussedItemIndex.current == 0 &&
      focussedItemIndex == resultsLength.current - 1
    ) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }

    lastFocussedItemIndex.current = focussedItemIndex;
  });

  let rowVirtualizer = useVirtual({
    size: results.length,
    parentRef,
    estimateSize: React.useCallback(() => 48, [])
  });

  let focussedItemRef = useRef<string | null>();
  focussedItemRef.current = focussedItem;

  let selectAction = (action?: IAction) => {
    if (!action && focussedItemRef.current) {
      action = results.find(a => a.id === focussedItemRef.current);
    }

    if (!action) return;

    if (!action.perform) {
      setCurrentAction(action.id);
      setSearch('');

      if (inputRef.current) inputRef.current.focus();
    } else {
      action.perform();
      setIsOpen(false);
    }
  };

  let back = (force?: boolean) => {
    if (!force && search.length > 0) {
      if (inputRef.current) inputRef.current.focus();
      return;
    }

    let lastItem = history[history.length - 2];

    if (lastItem) {
      if (lastItem.isHome) {
        setCurrentAction(null);
      } else {
        setCurrentAction(lastItem.id);
      }

      if (inputRef.current) inputRef.current.focus();
    }
  };

  useShortcut(
    ['Enter'],
    () => {
      if (!isOpen) return;
      selectAction();
    },
    [isOpen, results]
  );

  useShortcut(
    ['Backspace'],
    () => {
      if (!isOpen) return;
      back();
    },
    [history, isOpen, search]
  );

  useShortcut(
    ['ArrowDown'],
    () => {
      if (!isOpen) return;
      focusNextItem();
    },
    [isOpen]
  );

  useShortcut(
    ['ArrowUp'],
    () => {
      if (!isOpen) return;
      focusPreviousItem();
    },
    [isOpen]
  );

  useShortcut(
    ['Escape'],
    () => {
      if (!isOpen) return;
      setIsOpen(false);
    },
    [isOpen]
  );

  useEffect(() => {});

  return (
    <Wrapper
      onClick={() => {
        setIsOpen(false);
      }}
      className={clsx({ isOpen, isClosed: !isOpen })}
    >
      <Modal
        onClick={e => {
          e.stopPropagation();
        }}
        className={clsx({ isOpen, isClosed: !isOpen })}
        style={{ animation: animation }}
      >
        <ModalInner>
          <Header>
            <div className="inner">
              <LogoWrapper>{logo || <Search size={18} />}</LogoWrapper>

              {currentActionFull?.selectedName && (
                <CurrentAction>{currentActionFull.selectedName}</CurrentAction>
              )}

              <Input>
                <input
                  ref={inputRef}
                  aria-label="Search"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  autoFocus
                  placeholder={
                    currentActionFull?.searchPlaceholder ||
                    placeholder ||
                    'Type command or search'
                  }
                  onKeyDown={e => {
                    if (
                      e.key == 'Backspace' &&
                      (e.target as HTMLInputElement).value.length == 0
                    ) {
                      back(true);
                    }

                    if (e.key == 'ArrowDown' || e.key == 'ArrowUp') {
                      e.preventDefault();
                    }
                  }}
                />
              </Input>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <KBD>
                  <span>
                    {currentActionFull?.shortcut
                      ? formatKeyboardShortcut(currentActionFull.shortcut)
                      : formatKeyboardShortcut('mod+k')}
                  </span>
                </KBD>
              </div>
            </div>

            {!currentAction && Array.isArray(tips) && tips.length > 0 && (
              <Tips>
                {tips.map(tip => (
                  <button
                    key={tip.id}
                    onClick={() => {
                      setCurrentAction(tip.id);
                      setSearch('');
                      if (inputRef.current) inputRef.current.focus();
                    }}
                  >
                    {tip.name}
                  </button>
                ))}
              </Tips>
            )}
          </Header>

          <Results ref={parentRef}>
            <div
              style={{
                height: `${rowVirtualizer.totalSize}px`,
                width: '100%',
                position: 'relative'
              }}
            >
              <ul>
                {rowVirtualizer.virtualItems.map(virtualRow => {
                  let action = results[virtualRow.index];

                  return (
                    <li
                      key={action.id}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`
                      }}
                    >
                      <Result
                        onFocus={() => setFocussedItem(action.id)}
                        key={action.id}
                        action={action}
                        currentAction={currentAction}
                        isFocused={focussedItem == action.id}
                        onSelect={() => selectAction(action)}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </Results>
        </ModalInner>
      </Modal>
    </Wrapper>
  );
};

export let CommandMenu = ({
  placeholder,
  tips,
  logo
}: {
  placeholder?: string;
  tips?: ITips[];
  logo?: React.ReactElement;
}) => {
  let [isOpen, setIsOpen] = useCommandMenuOpen();
  let canRender = useDelayed(isOpen, 500, [true]);

  useShortcut(
    ['mod+k'],
    () => {
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  if (!canRender) return null;

  return <CommandMenuInner placeholder={placeholder} tips={tips} logo={logo} />;
};
