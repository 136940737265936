import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIsSSR } from './useIsSSR';

export let useQuery = (
  id: string,
  {
    def,
    emptyRoute
  }: {
    def?: string;
    emptyRoute?: string;
  } = {}
) => {
  let router = useRouter();
  let isSSR = useIsSSR();

  let value = Array.isArray(router.query[id])
    ? router.query[id][0]
    : (router.query[id] as string);

  if (!value && typeof window != 'undefined') {
    value = new URLSearchParams(window.location.search).get(id);
  }

  useEffect(() => {
    if (isSSR && !value && emptyRoute) router.replace(emptyRoute);
  }, [isSSR, value, emptyRoute]);

  return value || def;
};
