import { styled } from 'buttered';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

let Wrapper: any = styled('button')`
  background: none;
  border: none;
  border-bottom: solid transparent 2px;
  padding: 15px 10px 13px 10px;
  transition: all 0.3s;

  &.mobile {
    padding: 5px 10px 5px 10px;
  }

  &.sub {
    padding: 7px 0px 7px 0px;
    margin-right: 20px;

    &:hover {
      border-bottom: solid var(--vapor-primary) 2px;
    }
  }
`;

let Inner = styled('div')`
  border-radius: 5px;
  transition: all 0.3s;
  height: 30px;
  color: var(--vapor-accent-8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10px;
  font-size: 14px;

  &:hover {
    background: var(--vapor-accent-3);
    color: var(--vapor-foreground);
  }

  &.sub {
    padding: 0px;
    background: transparent !important;
  }
`;

let Content = styled('p')``;

export type INavItem = Parameters<typeof NavItem>[0];

export let NavItem = ({
  children,
  onClick,
  href,
  active,
  mobile,
  sub
}: {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  href?: string;
  active?: boolean;
  mobile?: boolean;
  sub?: boolean;
}) => {
  let inner = (
    <Wrapper
      onClick={onClick}
      as={href ? 'div' : 'button'}
      style={active ? { borderBottom: 'solid var(--vapor-foreground) 2px' } : undefined}
      className={clsx({ mobile, sub })}
    >
      <Inner className={clsx({ mobile, sub })}>
        <Content>{children}</Content>
      </Inner>
    </Wrapper>
  );

  if (href) {
    return (
      <Link href={href}>
        <a style={{ color: 'unset', textDecoration: 'none' }}>{inner}</a>
      </Link>
    );
  }

  return inner;
};
