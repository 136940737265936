import { styled } from 'buttered';

export let Desktop = styled('div')`
  display: flex;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export let Mobile = styled('div')`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    display: none;
  }
`;
