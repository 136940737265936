import { useRouter } from 'next/router';
import React from 'react';
import {
  Calendar,
  CreditCard,
  Grid,
  Lock,
  Mail,
  Settings,
  Smartphone,
  Users
} from 'react-feather';
import { CommandMenu } from '../command-menu';
import { Shortcuts } from '../command-menu/shortcuts';
import { BaseLayout } from '../components';
import { Navigation } from '../components/navigation';
import { useAsyncActions, useGlobalActions } from '../state/useActions';
import { useUser } from '../state/useBoot';
import { useTeamId } from '../state/useTeam';

export let Layout = ({
  children,
  pageId,
  layout
}: {
  children: React.ReactNode;
  pageId: string;
  layout: 'account' | 'global' | 'team';
}) => {
  let { user } = useUser();
  let { push, pathname } = useRouter();
  let teamId = useTeamId();

  useGlobalActions();
  useAsyncActions();

  return (
    <>
      <CommandMenu />
      <Shortcuts />

      <BaseLayout
        name="Weekrise"
        user={user}
        navItems={[
          {
            children: 'Launchpad',
            href: '/',
            active: pathname == '/'
          },
          {
            children: 'Today',
            href: '/today',
            active: pathname == '/today'
          }
        ]}
      >
        {layout == 'account' && (
          <Navigation
            activeSection="account"
            sections={[
              {
                id: 'account',
                name: 'Account',
                items: [
                  {
                    type: 'item',
                    icon: <Settings />,
                    name: 'Profile',
                    id: '/account',
                    active: pageId == 'profile'
                  },
                  {
                    type: 'item',
                    icon: <Grid />,
                    name: 'Teams',
                    id: '/account/teams',
                    active: pageId == 'teams'
                  },
                  {
                    type: 'item',
                    icon: <Calendar />,
                    name: 'Google Calendar',
                    id: '/account/gcal',
                    active: pageId == 'gcal'
                  },
                  {
                    type: 'item',
                    icon: <Mail />,
                    name: 'Digest',
                    id: '/account/digest',
                    active: pageId == 'digest'
                  },
                  {
                    type: 'header',
                    name: 'Security',
                    id: 'security'
                  },
                  {
                    type: 'item',
                    icon: <Lock />,
                    name: 'Login Methods',
                    id: '/account/login',
                    active: pageId == 'login'
                  },
                  {
                    type: 'item',
                    icon: <Smartphone />,
                    name: 'Devices',
                    id: '/account/devices',
                    active: pageId == 'devices'
                  }
                ]
              }
            ]}
            onItemClick={id => {
              push(`/${id}`);
            }}
          >
            {children}
          </Navigation>
        )}

        {layout == 'team' && (
          <Navigation
            activeSection="team"
            sections={[
              {
                id: 'team',
                name: 'Team',
                items: [
                  {
                    type: 'item',
                    icon: <Settings />,
                    name: 'Team',
                    id: `/team/${teamId}`,
                    active: pageId == 'profile'
                  },
                  {
                    type: 'item',
                    icon: <Grid />,
                    name: 'Calendars and Boards',
                    id: `/team/${teamId}/calendars`,
                    active: pageId == 'calendars'
                  },
                  {
                    type: 'item',
                    icon: <CreditCard />,
                    name: 'Subscription',
                    id: `/team/${teamId}/subscription`,
                    active: pageId == 'subscription'
                  },
                  {
                    type: 'item',
                    icon: <Users />,
                    name: 'Members',
                    id: `/team/${teamId}/members`,
                    active: pageId == 'members'
                  }
                ]
              }
            ]}
            onItemClick={id => {
              push(id);
            }}
          >
            {children}
          </Navigation>
        )}

        {layout == 'global' && children}
      </BaseLayout>
    </>
  );
};
