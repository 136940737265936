import { WeekriseLogo } from '@highloop-pkg/logo';
import { styled } from 'buttered';
import React from 'react';
import { IUser } from '../state/interfaces/user';
import { Nav } from './layout/nav';
import { INavItem } from './layout/nav/item';
import { IPagination } from './layout/nav/pagination';

let Wrapper = styled('div')`
  display: block;
`;

let Main = styled('div')`
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
`;

let Content = styled('main')``;

export let BaseLayout = ({ children, navItems, navSubItems, pagination, name, user }: { children: React.ReactNode; navItems: INavItem[]; navSubItems?: INavItem[]; pagination?: IPagination[]; user: IUser; name?: string }) => {
  return (
    <Wrapper>
      <Nav items={navItems} subnavItems={navSubItems} logo={<WeekriseLogo size={24} />} name={name} pagination={pagination} user={user} />

      <Main>
        <Content>{children}</Content>
      </Main>
    </Wrapper>
  );
};
