import { keyframes } from 'buttered';
import { useEffect, useState } from 'react';
import useDelayed from 'use-delayed';

let navigationKeyframes = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.985);
  }

  100% {
    transform: scale(1);
  }
`;

export let useNavigateAnimation = (currentAction: string, isOpen: boolean) => {
  let [animate, setAnimate] = useState(false);
  let isOpenDelayed = useDelayed(isOpen, 300);

  useEffect(() => {
    setAnimate(true);
    let timeout = setTimeout(() => setAnimate(false), 300);
    return () => clearTimeout(timeout);
  }, [currentAction, isOpen, isOpenDelayed]);

  return animate
    ? `${navigationKeyframes} 0.3s ease-in-out forwards`
    : isOpenDelayed && isOpen
    ? 'none'
    : undefined;
};
