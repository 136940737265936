import React, { DependencyList, useEffect, useMemo, useState } from 'react';
import createGlobalState from 'use-state-global';
import { matchSorter } from 'match-sorter';

export interface IAction {
  id: string;
  name: string;
  selectedName?: string; // Will be displayed next to the search input if selected
  searchPlaceholder?: string;
  parent?: string;
  keywords?: string | string[];
  icon?: React.ReactElement;
  hideInSearch?: boolean;
  perform?: () => void;
  hidden?: boolean; // Action can only be selected programmatically (this is useful for actions only triggered by a tip)
  priority?: number; // Higher priority actions will be displayed first (especially useful for scoped actions)
  exclusive?: boolean; // If true only sub-actions can be searched if this action is the current action
  shortcut?: string[] | string;
}

export type IHistoryItem = {
  isHome: boolean;
} & IAction;

let useEnabledState = createGlobalState(false);
let useIsOpen = createGlobalState(false);
export let useActionsState = createGlobalState<IAction[]>([]);
export let useCurrentActionState = createGlobalState<string | null>(null);
let useSearchState = createGlobalState<string>('');

export let useKeyboardShortcutActions = () => {
  let [actions] = useActionsState();
  return useMemo(() => actions.filter(action => action.shortcut), [actions]);
};

export let useCommandMenuOpen = useIsOpen;

export let useCommandMenu = () => {
  let [enabled, setEnabled] = useEnabledState();
  let [actions] = useActionsState();
  let [currentAction, setCurrentAction] = useCurrentActionState();
  let [search, setSearch] = useSearchState();
  let [isOpen, setIsOpen] = useIsOpen();

  let history = useMemo<IHistoryItem[]>(() => {
    let history = [];

    if (currentAction) {
      let currentItem = actions.find(a => a.id === currentAction);
      let items = [currentItem];

      while (currentItem.parent) {
        currentItem = actions.find(a => a.id === currentItem.parent);
        items.push(currentItem);
      }

      history = items.reverse().map(item => ({
        isHome: false,
        ...item
      }));
    }

    return [
      {
        id: '__home',
        isHome: true,
        name: 'Home'
      },
      ...history
    ];
  }, [currentAction, actions]);

  let currentActionFull = useMemo(() => {
    if (history.length == 1) return null;
    return history[history.length - 1];
  }, [history]);

  let currentItems = useMemo(() => {
    if (!currentAction) return actions;
    return actions.filter(a => a.parent === currentAction);
  }, [actions]);

  let results = useMemo(() => {
    let results: IAction[] = [];

    if (!search) {
      results = currentItems
        .filter(a => (currentAction ? a.parent == currentAction : !a.parent))
        .sort((a, b) => {
          if (a.priority && b.priority) return a.priority - b.priority;
          if (a.priority) return -1;
          if (b.priority) return 1;
          return 0;
        });
    } else {
      results = matchSorter(
        currentItems.filter(a =>
          currentActionFull?.exclusive
            ? a.parent == currentAction
            : !a.hideInSearch || a.parent == currentAction
        ),
        search,
        { keys: ['name', 'keywords'] }
      );
    }

    return results.filter(a => !a.hidden).slice(0, 200);
  }, [currentItems, search, currentAction, currentActionFull]);

  let [focussedItem, setFocussedItem] = useState(() =>
    Array.isArray(results) && results.length > 0 ? results[0].id : null
  );

  useEffect(() => {
    let current = results.find(r => r.id == focussedItem);

    if (!current) {
      setFocussedItem(Array.isArray(results) && results.length > 0 ? results[0].id : null);
    }
  }, [focussedItem, results]);

  useEffect(() => {
    if (!isOpen) {
      let to = setTimeout(() => {
        setCurrentAction(null);
        setSearch('');
      }, 300);

      return () => clearTimeout(to);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setFocussedItem(Array.isArray(results) && results.length > 0 ? results[0].id : null);
    }
  }, [isOpen, search]);

  let focussedItemIndex = useMemo(
    () => results.findIndex(r => r.id === focussedItem),
    [results, focussedItem]
  );

  let focusNextItem = () => {
    let next = results[focussedItemIndex + 1] || results[0];
    setFocussedItem(next.id);
  };

  let focusPreviousItem = () => {
    let previous = results[focussedItemIndex - 1] || results[results.length - 1];
    setFocussedItem(previous.id);
  };

  return {
    focussedItem,
    focussedItemIndex,
    results,
    enabled,
    setEnabled,
    history,
    currentAction,
    setCurrentAction,
    setSearch,
    currentActionFull,
    isOpen,
    search,
    setIsOpen,
    focusNextItem,
    focusPreviousItem,
    setFocussedItem
  };
};

let arrayToMap = <T extends { id: string }>(arr: T[]) => {
  return arr.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as { [key: string]: T });
};

export let useActions = (actions: IAction[], dependencies: DependencyList = []) => {
  useEffect(() => {
    let newActionsMap = arrayToMap(actions);
    let addedActionIds = [];

    let currentActions = useActionsState.getState().map(a => {
      if (newActionsMap[a.id]) {
        addedActionIds.push(a.id);
        return newActionsMap[a.id];
      }

      return a;
    });

    let newActions = actions.filter(a => !addedActionIds.includes(a.id));
    useActionsState.setState([...currentActions, ...newActions]);
  }, dependencies);
};

export let useLocalActions = (
  actions: IAction[],
  active: boolean = true,
  dependencies: DependencyList = []
) => {
  useEffect(() => {
    if (!active) return;

    let newActionsMap = arrayToMap(actions);
    let newActionIds = actions.map(a => a.id);
    let addedActionIds = [];

    let currentActions = useActionsState.getState().map(a => {
      if (newActionsMap[a.id]) {
        addedActionIds.push(a.id);
        return newActionsMap[a.id];
      }

      return a;
    });

    let newActions = actions.filter(a => !addedActionIds.includes(a.id));
    useActionsState.setState([...currentActions, ...newActions]);

    return () => {
      let currentActions = useActionsState
        .getState()
        .filter(a => !newActionIds.includes(a.id));

      useActionsState.setState(currentActions);
    };
  }, [...dependencies, active]);
};

export let useDynamicSubActions = (
  action: IAction,
  subActions: IAction[],
  dependencies: DependencyList = []
) => {
  useActions([action], dependencies);

  useEffect(() => {
    useActionsState.setState([
      ...useActionsState.getState().filter(a => a.parent != action.id),
      ...subActions.map(a => ({
        ...a,
        id: action.id + '/' + a.id,
        parent: action.id
      }))
    ]);

    return () => {
      useActionsState.setState([
        ...useActionsState.getState().filter(a => a.parent != action.id)
      ]);
    };
  }, dependencies);
};

export let useSettingsAction = ({
  action,
  options,
  onSelect,
  hideInSearch
}: {
  action: IAction;
  options: { name: string; id: string; icon?: React.ReactElement; keywords?: string }[];
  onSelect: (id: string) => void;
  hideInSearch?: boolean;
}) => {
  useDynamicSubActions(
    action,
    options.map(o => ({
      ...o,
      parent: action.id,
      hideInSearch,
      perform: () => onSelect(o.id)
    })),
    [action]
  );
};

export let openCommandMenu = ({
  search,
  currentAction
}: {
  search?: string;
  currentAction?: string;
} = {}) => {
  useIsOpen.setState(true);
  useSearchState.setState(search || '');
  useCurrentActionState.setState(currentAction || null);
};

export let closeCommandMenu = () => {
  useIsOpen.setState(false);
};
