import { useCallback } from 'react';
import useSWR from 'swr';
import { useQuery } from '../../hooks/useQuery';
import { ICalendar } from './interfaces/calendar';
import { ITeam, ITeamMember } from './interfaces/team';
import { aresFetcher, client, getAuthHeaders } from './sdk/client';
import { useBoot } from './useBoot';

export let useTeamId = () => {
  let teamId = useQuery('teamId');

  return teamId;
};

export let useTeam = (teamId: string, opts: { poll?: boolean } = {}) => {
  let { data, error, mutate } = useSWR<{
    team: ITeam;
    calendars: ICalendar[];
    members: ITeamMember[];
  }>(
    teamId ? `/team/${teamId}` : null,
    aresFetcher,
    opts.poll
      ? {
          refreshInterval: 1500
        }
      : undefined
  );
  let { revalidate, createCalendar } = useBoot();

  let updateTeam = async (data: { name?: string; photoUrl?: string | null }) => {
    let res = await client.put<{ team: ITeam }>(`/team/${teamId}`, data, {
      headers: await getAuthHeaders()
    });

    mutate(d => ({
      ...d,
      team: {
        ...d.team,
        ...res.data.team
      }
    }));

    revalidate();

    return res.data.team;
  };

  let regenerate = async () => {
    let res = await client.post<{ team: ITeam }>(
      `/team/${teamId}/invite/regenerate`,
      {},
      {
        headers: await getAuthHeaders()
      }
    );

    mutate(d => ({
      ...d,
      team: {
        ...d.team,
        ...res.data.team
      }
    }));

    return res.data.team;
  };

  let invite = async (email: string) => {
    await client.post(
      `/team/${teamId}/invite/email`,
      { email },
      {
        headers: await getAuthHeaders()
      }
    );
  };

  let removeMember = async (userId: string) => {
    let res = await client.delete<{ member: ITeamMember }>(
      `/team/${teamId}/member/${userId}`,
      {
        headers: await getAuthHeaders()
      }
    );

    mutate(d => ({
      ...d,
      members: d.members.filter(m => m.id != res.data.member.id)
    }));

    return res.data.member;
  };

  let deleteCalendar = async (calendarId: string) => {
    let res = await client.delete<{ calendar: ICalendar }>(`/calendar/${calendarId}`, {
      headers: await getAuthHeaders()
    });

    mutate(d => ({
      ...d,
      calendars: d.calendars.filter(c => c.id != res.data.calendar.id)
    }));

    revalidate();

    return res.data.calendar;
  };

  return {
    team: data?.team,
    calendars: data?.calendars,
    members: data?.members,
    createCalendar: async (data: Parameters<typeof createCalendar>[1]) => {
      let res = await createCalendar(teamId, data);
      revalidate();
      return res;
    },
    updateTeam,
    regenerate,
    removeMember,
    deleteCalendar,
    revalidate: useCallback(() => mutate(), [mutate]),
    mutate,
    invite,
    error,
    loading: !data && !error
  };
};

export let useTeamSubscription = (teamId: string) => {
  let { team } = useTeam(teamId);

  let { data, error } = useSWR<any>(
    team?.id ? `/team/${teamId}/subscription` : null,
    aresFetcher
  );

  let upgradeToTeamPlan = async () => {
    return client.post(
      `/team/${teamId}/subscription/upgradeToTeamPlan`,
      {},
      {
        headers: await getAuthHeaders()
      }
    );
  };

  return {
    transactions: data?.transactions,
    subscription: data?.subscription,
    upgradeToTeamPlan,
    error,
    loading: !data && !error
  };
};
