import { Menu } from '@vapor/ui';
import { styled } from 'buttered';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { ChevronRight } from 'react-feather';
import { Mobile } from '../viewport';

export interface IPaginationSelection {
  type: 'selection';
  currentId: string;
  action: (id: string) => unknown;
  items: (
    | {
        type?: 'item';
        id: string;
        name: string;
        description?: string;
        avatar?: React.ReactNode;
      }
    | {
        type: 'separator';
      }
  )[];
}

export interface IPaginationEntry {
  type: 'entry';
  label: string;
}

export type IPagination = IPaginationSelection | IPaginationEntry;

let Wrapper = styled('div')`
  display: flex;
  margin-left: 10px;
`;

let PaginationEntryWrapper = styled('div')`
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0px 12px;

  &.active {
    font-weight: 600;
  }
`;

let PaginationEntrySelection = styled('div')`
  button {
    height: 30px;
    border: none;
    background: transparent;
    border-radius: 6px;
    transition: all 0.3s;
    font-size: 14px;
    display: flex;
    padding: 0px;

    &:focus,
    &:hover {
      background: var(--vapor-accent-3);
    }

    .name {
      line-height: 30px;
      padding: 0px 12px;
    }

    &.hasAvatar {
      .name {
        padding: 0px 12px 0px 8px;
      }
    }
  }
`;

let PaginationSelection = ({
  item,
  isLink
}: {
  item: IPaginationSelection;
  isLink?: boolean;
}) => {
  let currentItem = useMemo(
    () => item.items.find(i => i.type != 'separator' && i.id === item.currentId),
    [item.currentId, item.items]
  );

  return (
    <PaginationEntrySelection>
      {isLink ? (
        <button
          onClick={() => item.action((currentItem as any).id)}
          className={clsx({ hasAvatar: !!(currentItem as any)?.avatar })}
          style={{ color: 'var(--vapor-foreground)' }}
        >
          {(currentItem as any)?.avatar && (
            <div className="avatar">{(currentItem as any)?.avatar}</div>
          )}

          <p className="name" style={{ color: 'var(--vapor-foreground)' }}>
            {(currentItem as any)?.name || ''}
          </p>
        </button>
      ) : (
        <Menu
          minWidth={140}
          label="Demo Menu"
          action={id => item.action(id)}
          items={item.items.map((value, i) =>
            value.type == 'separator'
              ? {
                  id: 'sp' + i,
                  type: 'separator'
                }
              : {
                  id: value.id,
                  type: 'item',
                  component: (
                    <Menu.Entity
                      title={
                        <span
                          style={{
                            fontWeight: !value.description && !value.avatar ? 400 : 500
                          }}
                        >
                          {value.name}
                        </span>
                      }
                      description={value.description}
                      icon={value.avatar}
                    />
                  )
                }
          )}
        >
          {({ attrs, ref }) => (
            <button
              {...attrs}
              ref={ref}
              className={clsx({ hasAvatar: !!(currentItem as any)?.avatar })}
              style={{ color: 'var(--vapor-foreground)' }}
            >
              {(currentItem as any)?.avatar && (
                <div className="avatar" style={{ color: 'var(--vapor-foreground)' }}>
                  {(currentItem as any)?.avatar}
                </div>
              )}

              <p className="name" style={{ color: 'var(--vapor-foreground)' }}>
                {(currentItem as any)?.name || ''}
              </p>
            </button>
          )}
        </Menu>
      )}
    </PaginationEntrySelection>
  );
};

let PaginationEntry = ({ item, active }: { item: IPaginationEntry; active?: boolean }) => {
  return (
    <PaginationEntryWrapper className={clsx({ active })}>{item.label}</PaginationEntryWrapper>
  );
};

export let Pagination = ({
  pagination,
  isMobile
}: {
  pagination: IPagination[];
  isMobile?: boolean;
}) => {
  let lastSelectionItemIndex = useMemo(() => {
    return pagination.reduce((acc, v, i) => {
      if (v.type === 'selection') return i;
      return acc;
    }, undefined);
  }, [pagination]);

  let firstSelectableItemIndex = useMemo(() => {
    return pagination.findIndex(v => v.type === 'selection');
  }, [pagination]);

  let renderedItems = pagination.filter((item, i) => {
    if (
      item.type == 'selection' &&
      (!item.currentId || !Array.isArray(item.items) || item.items.length == 0)
    )
      return;

    if (isMobile) return i == firstSelectableItemIndex;
    return true;
  });

  return (
    <Wrapper>
      {renderedItems.length > 0 && (
        <Mobile>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginRight: 9,
              height: 30
            }}
          >
            <ChevronRight size={16} />
          </div>
        </Mobile>
      )}

      {renderedItems.map((item, index) => (
        <div style={{ display: 'flex' }} key={index}>
          {item.type === 'entry' ? (
            <PaginationEntry item={item} active={index == renderedItems.length - 1} />
          ) : (
            <PaginationSelection
              item={item}
              isLink={index < lastSelectionItemIndex || isMobile}
            />
          )}

          {index < renderedItems.length - 1 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '0px 6px'
              }}
            >
              <ChevronRight size={16} />
            </div>
          )}
        </div>
      ))}
    </Wrapper>
  );
};
