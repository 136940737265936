import { useEffect, useState } from 'react';

export let useIsMobile = () => {
  let [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window == 'undefined') return;

    let handle = () => {
      setIsMobile(window.innerWidth < 700);
    };

    handle();

    window.addEventListener('resize', handle);
    return () => window.removeEventListener('resize', handle);
  }, []);

  return isMobile;
};
