import { styled } from 'buttered';
import clsx from 'clsx';
import { useEffect, useMemo, useRef } from 'react';
import { ChevronRight } from 'react-feather';
import scrollIntoView from 'scroll-into-view-if-needed';
import { KBD } from '.';
import { formatKeyboardShortcut } from './lib';
import { IAction, useActionsState } from './useCommandMenu';

let ResultWrapper = styled('button')`
  padding: 12px 20px;
  border-radius: 6px;
  display: flex;
  color: #ccc;
  background: none;
  border: none;
  width: 100%;

  p,
  figure {
    padding: 0px;
    margin: 0px;
    height: 20px;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  figure {
    margin-right: 9px;
    display: flex;
    align-items: center;
    width: 20px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
  }

  &.isFocused {
    background: #333;
    color: white;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    p {
      display: flex;
      height: 20px;
      line-height: 20px;
    }

    .parent {
      display: flex;
      opacity: 0.6;

      span {
        display: flex;

        &.arr {
          padding: 0px 3px;

          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export let Result = ({
  action,
  isFocused,
  currentAction,
  onFocus,
  onSelect
}: {
  action: IAction;
  currentAction: string;
  isFocused: boolean;
  onFocus: () => void;
  onSelect: () => void;
}) => {
  let ref = useRef<HTMLButtonElement>();
  let [actions] = useActionsState();

  let parents = useMemo(() => {
    if (!action.parent) return [];
    if (action.parent == currentAction) return [];

    let parents: IAction[] = [];
    let parent = actions.find(a => a.id === action.parent);

    while (parent) {
      parents.push(parent);
      parent = actions.find(a => a.id === parent.parent);
    }

    return parents;
  }, [actions, action, currentAction]);

  useEffect(() => {
    if (isFocused && ref.current) {
      scrollIntoView(ref.current, {
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }, [isFocused]);

  return (
    <ResultWrapper
      ref={ref}
      className={clsx({ isFocused })}
      role="button"
      aria-current={isFocused ? 'true' : 'false'}
      onMouseEnter={onFocus}
      onClick={onSelect}
    >
      <figure>{action.icon || null}</figure>
      <div className="main">
        <p>
          {parents.length > 0 &&
            parents.map((parent, index) => (
              <span className="parent" key={parent.id}>
                <span>{parent.selectedName || parent.name}</span>
                <span className="arr">
                  <ChevronRight size={10} />
                </span>
              </span>
            ))}

          {action.name}
        </p>
      </div>

      {action.shortcut && (
        <KBD highlight={isFocused}>
          <span>{formatKeyboardShortcut(action.shortcut)}</span>
        </KBD>
      )}
    </ResultWrapper>
  );
};
